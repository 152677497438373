import PropTypes            from 'prop-types'
// import moment               from 'moment'

// TODO Add custom PropTypes
// const propTypes = {
//     moment: (props, propName, componentName) => {
//         // Optional if onCalendarDatesChange it's defined
//         if(!moment.isMoment(props[propName])) {
//             const type = typeof props[propName]
//             return new Error(`Expected moment object for ${propName}, got ${type} type instead.`)
//         }
//     },
// }

export default PropTypes
