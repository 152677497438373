import React                  from 'react'
import AppHeader              from 'components/AppHeader'
import AppFooter              from 'components/AppFooter'
import CartHandler            from 'components/CartHandler'
// import BrowserUpdate          from 'components/BrowserUpdate'
import styles                 from './styles.module.scss'
// import 'bootstrap/dist/css/bootstrap.min.css'
import 'css/bootstrap.min.css'
import 'css/fonts.scss'
import 'css/_global.scss'
import 'css/bootstrap-overwrite.scss'
import AnnouncementsComponent from '../AnnouncementsComponent';

const App = ({ children, props }) => {

    return (
        <CartHandler>
            <div className={styles.root}>
                <AppHeader />
                <main>
                    {children}
                </main>
                <AppFooter />
                <AnnouncementsComponent />
                {/* <BrowserUpdate /> */}
            </div>
        </CartHandler>
    )
}

export default App
