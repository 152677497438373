import React                  from 'react'
import cx                     from 'utils/classnames'
import {
    useStaticQuery,
    graphql
}                             from 'gatsby'
import { map }                from 'lodash-es'
import {
    useContext,
    getContextState
}                             from 'entrada-ui/utils'
import NavBar                 from 'entrada-ui/NavBar'
import NavBarToggler          from 'entrada-ui/NavBarToggler'
import NavBarCollapse         from 'entrada-ui/NavBarCollapse'
import Nav                    from 'entrada-ui/NavBarNav'
import NavItem                from 'entrada-ui/NavBarNavItem'
import Spacer                 from 'entrada-ui/Spacer'
import CartHandler            from 'components/CartHandler'
import Link                   from 'components/Link'
import Logo                   from 'components/Logo'
import MenuIcon               from './MenuIcon'
import CartIcon               from './CartIcon'
import styles                 from './styles.module.scss'

const HEADER_QUERY = graphql`
{
    wordpressMenusMenusItems(name: {eq: "Main menu"}) {
        items {
            slug
            title
            url
            type
        }
    }
}
`

const ICON_OFFSETS = {
    'milford sound': 0,
    'mt cook': -35,
    'bay of islands': -70,
    'hobbiton': -105,
    'travel': -140,
    'about us': -175,
    'accommodation': -210,
    'contact': -245
}

const NavItemIcon = ({ title }) => {
    const offset = ICON_OFFSETS[title.toLowerCase()] || 0

    return (
        <span className={styles.icon} style={{ backgroundPosition: `0 ${offset}px` }} />
    )
}

let prevScroll = 0

const AppHeader = (props) => {
    const wrapperRef = React.useRef()
    const navBarRef = React.useRef()
    const [navHeight, setNavHeight] = React.useState(0)
    const [scrollingDown, setScrollingDown] = React.useState(false)
    // This is true when we scrolled more than the height of the nav bar.
    // At this point we potentialy are going to hide the nav bar if we are scrolling down.
    const [navBarOffsetted, setNavBarOffsetted] = React.useState(false)

    React.useEffect(() => {
        const handleScrollWindow = (ev) => {
            const newScroll = window.pageYOffset
            setScrollingDown(prevScroll < newScroll)
            setNavBarOffsetted(newScroll >= navBarRef.current.clientHeight)
            setNavHeight(navBarRef.current.clientHeight)

            prevScroll = newScroll
        }

        window.addEventListener('scroll', handleScrollWindow)


        return () => window.removeEventListener('scroll', handleScrollWindow)
    }, [])

    const {
        wordpressMenusMenusItems: {
            items
        }
    } = useStaticQuery(HEADER_QUERY)

    const context = useContext(CartHandler.Context)
    const {
        totalCartItems,
    } = getContextState({
        props,
        states: [
            'totalCartItems',
        ],
        context
    })

    return (
        <div ref={wrapperRef} className={styles.navBarWrapper} style={{ height: navHeight ? `${navHeight}px` : undefined }}>
            <NavBar
                ref={navBarRef}
                className={cx(
                    styles.navBar,
                    navHeight && styles.navBarFixed,
                    scrollingDown && navBarOffsetted && styles.hideNavBar
                )}
                aria-label="Main"
            >
                <NavBarToggler>
                    <MenuIcon />
                </NavBarToggler>
                <Logo className={styles.logo} />
                <Spacer />
                <NavBarCollapse
                    Logo={<Logo className={styles.menuLogo} />}
                >
                    <Nav aria-label="Main" className={styles.mainNav}>
                        {map(items, (item, idx) => (
                        <NavItem className={styles.linkList} key={idx}>
                            {item.type === 'post_type' ?
                            <Link className={styles.link} to={`/${item.slug}`}>
                                {/* <NavItemIcon {...item} /> */}
                                <span>{item.title}</span>
                            </Link>
                            :
                            <Link className={styles.link} to={`${item.url}`}>
                                {/* <NavItemIcon {...item} /> */}
                                <span>{item.title}</span>
                            </Link>
                            }
                        </NavItem>
                        ))}
                    </Nav>
                </NavBarCollapse>
                {!!3 &&
                <CartIcon badge={totalCartItems} />
                }
            </NavBar>
        </div>
    )
}

export default AppHeader



