import React                  from 'react'
import Icon                   from 'entrada-ui/Icon'
import Link                   from 'components/Link'
import IconSvg                from 'images/cart-icon.svg'
import styles                 from './styles.module.scss'

const CartIcon = ({ badge }) => {
    return (
        <>
        {badge &&
        <Link to="/cart" className={styles.cartIcon}>
            <Icon>
                <span className={styles.badge}>{badge}</span>
                <img src={IconSvg} />
            </Icon>
        </Link>
        }
        </>
    )
}

export default CartIcon
