import React                from 'react'
import {
    graphql,
    useStaticQuery
}                           from 'gatsby'
import { map }              from 'lodash-es'
import {
    FaYoutube,
    FaFacebook,
    FaInstagram
}                           from 'react-icons/fa'
import {
    Col,
    Container,
    Row
}                           from 'react-bootstrap'
import Icon                 from 'entrada-ui/Icon'
import Link                 from 'components/Link'
import Logo                 from 'components/Logo'
import FooterNav            from './FooterNav'
import styles               from './styles.module.scss'

const FOOTER_MENU_QUERY = graphql`
{
    wordpressMenusMenusItems(name: {eq: "Footer menu"}) {
        items {
            title
            slug
            url
            child_items {
              title
              slug
              url
            }
          }
    }
}
`

const AppFooter = (props) => {
    const {
        wordpressMenusMenusItems: {
            items
        }
    } = useStaticQuery(FOOTER_MENU_QUERY)

    return (
        <div className={styles.appFooter}>
            <Container>
                <Row>
                    <Col xs={12} lg={4} className={styles.logoArea}>
                        <Logo className={styles.logo} dark={true} />

                        <div className={styles.footerContactDetails}>
                            <p>
                                International: <a href="tel:006493576032">(+64) 9357 6032</a> <br/>
                                NZ (Free): <a href="tel:0508365744">0508 DOLPHINS (365 744)</a>
                            </p>
                            <p>
                                Email: <a href="mailto:bookings@awads.co.nz">bookings@awads.co.nz</a>
                            </p>
                            <p className={styles.contactLocation}>
                                Berth 9, Eastern Viaduct Marina, Auckland 1010
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} lg={8}>
                        <FooterNav menu={items}>
                            {map(items, (item, idx) => (
                            <FooterNav.Item
                                key={idx}
                                title={item.title}
                                parentSlug={item.slug}
                                items={item.child_items}
                            />
                            ))}
                        </FooterNav>
                    </Col>
                    
                    <Col xs={12}>
                        <hr />
                        <div className={styles.subFooter}>
                            <div className="text-center">
                                <div className={styles.socialTitle}>
                                    Follow us
                                </div>
                                <div className={styles.socialIcons}>
                                    <a href="https://www.facebook.com/aucklandwhaleanddolphinsafari/" target="_blank">
                                        <Icon className={styles.socialIcon}>
                                            <FaFacebook />
                                        </Icon>
                                    </a>

                                    <a href="https://www.instagram.com/aucklandwhaleanddolphinsafari/" target="_blank">
                                        <Icon className={styles.socialIcon}>
                                            <FaInstagram />
                                        </Icon>
                                    </a>

                                    <a href="https://www.youtube.com/channel/UCsc2A95tITgSORRQ3J7RIzw/" target="_blank">
                                        <Icon className={styles.socialIcon}>
                                            <FaYoutube />
                                        </Icon>
                                    </a>
                                </div>
                            </div>
                            <div className={styles.termsWrap}>
                                <div className={styles.links}>
                                    <Link to="/terms-and-conditions/"> Terms and Conditions </Link> | <a href="https://entradatravelgroup.com/privacy-policy/">Privacy Policy</a>
                                </div>
                                <div>
                                    &copy; {new Date().getFullYear()} Entrada Travel Group
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AppFooter
